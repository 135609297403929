import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography, Button, MenuItem, Select, FormControl, InputLabel, useTheme, useMediaQuery, Card, CardMedia, CardContent } from '@mui/material';
import { IMenu, IDefContact } from 'features/setup/models/MainInformation';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';

const Formulaire = () => {
  const { getDefContact, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: defContact } = useQuery<IDefContact[]>(
    ['DefContact', selectedMenuId],
    () => getDefContact(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[4].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
 
  

 

  // Ensure defContact data exists before rendering
  const contactData = defContact ? defContact[0] : { image_Url: '', titre: '' };

  return (
    <Box py={4} px={2} sx={{ width: '100%' }}>
      <Grid container spacing={4}>
        {/* Form Section */}
        <Grid item xs={12} md={4}>
          <Box
            component="form"
            sx={{
              backgroundColor: '#f9f9f9',
              borderRadius: '16px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
              padding: '20px',
            }}
          >
            <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '20px', textAlign: 'center' }}>
              Vous préférez nous joindre en privé ? Utilisez donc ce formulaire.
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  name="name"
                  inputProps={{
                    style: {
                      textTransform: 'none',
                      fontFamily: 'Poppins',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Email"
                  name="email"
                  type="email"
                  inputProps={{
                    style: {
                      textTransform: 'none',
                      fontFamily: 'Poppins',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#fff', borderRadius: '8px' }}>
                  <InputLabel id="subject-label">Subject</InputLabel>
                  <Select
                    labelId="subject-label"
                    label="Subject"
                    name="subject"
                    sx={{ fontFamily: 'Poppins' }}
                  >
                    <MenuItem value="General Inquiry">General Inquiry</MenuItem>
                    <MenuItem value="Support">Support</MenuItem>
                    <MenuItem value="Feedback">Feedback</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Message"
                  name="message"
                  multiline
                  rows={4}
                  inputProps={{
                    style: {
                      textTransform: 'none',
                      fontFamily: 'Poppins',
                      backgroundColor: '#fff',
                      borderRadius: '8px',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: '8px',
                    padding: '10px 20px',
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                  }}
                >
                  Send Message
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Contact Information Section */}
        <Grid item xs={12} md={8}>
          <Card>
            <Box sx={{ position: 'relative' }}>
              <CardMedia
                component="img"
                height="590"
                sx={{ width: '100%', objectFit: 'fill' }}
                image={`${globalConfig.get().apiUrl}/download/${contactData.image_Url}`}
                alt={contactData.titre || 'Contact Image'}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '590px',
                  bgcolor: 'rgba(0, 0, 0, 0.5)', // Voile semi-transparent
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontFamily: 'Poppins',
                  textAlign: 'center',
                  px: 2
                }}
              >
                <Typography variant="h5" sx={{ mb: 2 }}>
                  {contactData.titre || 'Contact Information'}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Formulaire;
