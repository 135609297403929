
export interface IMenu {
    id: number;
    menu: string;
  }
  
 





export interface IMainInformation {
    id: number, 
    portable: string,
    logo: string,
    logo_Url: string,
    email: string,
    horaire: string,
    titreCentre : string,
    titreBienvenue: string,
    descriptionBienvenue : string,
    lienFacebook : string,
    lienLinkedin : string,
    lienTwitter : string,
    lienInstagram : string,
    localisation : string,
    portable2: string,
    localisation2: string
}

export interface IDefAboutUs{
    id:number;
    titre : string;
    image : string;
    image_Url : string;

}


export interface IDefContact{
    id:number;
    titre : string;
    image : string;
    image_Url : string;

}

export interface IDefFonctionnalite{
    id:number;
    titre : string;
    image : string;
    image_Url : string;

}

export interface IGalery{
    id:number;
    image : string;
    image_Url : string;
}

export interface IDefTarif{
    id:number;
    titre : string;
    image : string;
    image_Url : string;

}



export interface IValue{
    id:number;
    image : string;
    image_Url : string;
    description: string;
    titre:string;
}

export interface IHistory{
    id:number;
    image : string;
    image_Url : string;
    description: string;
    titreSecondaire:string;
}

export interface IHeroSection{
    id:number;
    image : string;
    image_Url : string;
    description: string;
    titre:string;

}

export interface IManagement{
    id:number;
    image : string;
    image_Url : string;
    titreSecondaire: string;
    titre:string;

}

export interface IFonctionnalite{
    id:number;
    image : string;
    image_Url : string;
    titre:string;
    description:string;

}

export interface IReference{
    id:number;
    image : string;
    image_Url : string;

}

export interface IServiceClient{
    id:number;
    image : string;
    image_Url : string;
    titrePrincipal:string;
    titreSecondaire : string;

}

export interface IAvantage{
    id:number;
    image : string;
    image_Url : string;
    titrePrincipal:string;
    titreSecondaire : string;

}

export interface IAbout{
    id:number;
    image : string;
    image_Url : string;
    description:string;

}

export interface IOurHistory{
    id:number;
    image : string;
    image_Url : string;
    titrePrincipal:string;
    description:string;

}

export interface IDetails{
    id:number;
    titre:string;
    nombre:string;
}

export interface IPrix{
    id:number;
    titre:string;
    montant:string;
    desc1:string;
    desc2:string;
    desc3:string;
    desc4:string;
    desc5:string;

}

export interface IFAQ{
    id:number;
    question:string;
    reponse:string;
}

export interface IAllFonctionnalite{
    id:number;
    image : string;
    image_Url : string;
    titre:string;
    alias:string;
    description:string;
}

export interface IMessage{
    id:number;
    message:string;

}

export interface IDetailsScolarite{
    id:number;
    titre:string;
    image : string;
    image_Url : string;
    description:string;
}

export interface IDetailFonction{
    id:number;
    titre:string;
    image : string;
    image_Url : string;
    description:string;
}


export const defaultMainInformation : IMainInformation = {
    id: 0,
    portable: '',
    logo: '',
    logo_Url: '',
    email: '',
    horaire: '',
    titreCentre: '',
    titreBienvenue: '',
    descriptionBienvenue: '',
    lienFacebook: "",
    lienLinkedin: "",
    lienTwitter: "",
    lienInstagram: "",
    localisation: "",
    portable2: "",
    localisation2: ""
}



export const defaultMessage : IMessage = {
    id: 0,
    message: ""
}