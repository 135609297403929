import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IManagement, IMenu } from './models/MainInformation';
import { globalConfig } from 'config';

const Management = () => {
  const { t } = useTranslation();
  const { getManagement, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: manages } = useQuery<IManagement[]>(
    ['Management', selectedMenuId],
    () => getManagement(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      color="black"
      py={2}
      px={2}
      textAlign="center"
      width="100%"
      sx={{ 
        background: '#fff',
        marginTop:'30px'
      }}
    >
      <Grid container spacing={3}>
        {manages?.map((manage) => (
          <Grid container item xs={12} spacing={2} key={manage.id}>


<Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <img 
                src={`${globalConfig.get().apiUrl}/download/${manage.image_Url}`} 
                alt={manage.image} 
                style={{ width: '100%', height: '500px' }}
              />
            </Grid>
            
            <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center">
              <Typography variant= "h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500',color:'#000',marginBottom: '20px' }}>
                {manage.titre}
              </Typography>
              <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins',textAlign:'justify',marginBottom: '150px' }}>
                {manage.titreSecondaire}
              </Typography>
            </Grid>

          
          </Grid>
        ))}
      </Grid>
      
    </Box>
  );
};

export default Management;
