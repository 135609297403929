import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IOurHistory, IMenu } from './models/MainInformation';
import { globalConfig } from 'config';

const OurHistory = () => {
  const { t } = useTranslation();
  const { getOurHistory, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: histories } = useQuery<IOurHistory[]>(
    ['OurHistory', selectedMenuId],
    () => getOurHistory(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[1].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      color="black"
      py={2}
      px={2}
      textAlign="center"
      width="100%"
      sx={{ 
        background: '#fff',
        marginTop:'30px'
      }}
    >
      <Grid container spacing={3}>
        {histories?.map((history) => (
          <Grid container item xs={12} spacing={2} key={history.id}>
   
            <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins',  textAlign:'justify' }}>
                {history.titrePrincipal}
              </Typography>
              <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins' , textAlign:'justify'}}>
                {history.description}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <img 
                src={`${globalConfig.get().apiUrl}/download/${history.image_Url}`} 
                alt={history.image} 
                style={{ width: '100%', height: '500px' }}
              />
            </Grid>
          
          </Grid>
        ))}
      </Grid>
      <Typography sx={{ marginTop: '50px' }}></Typography>
    </Box>
  );
};

export default OurHistory;
