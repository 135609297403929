import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';

const Details = () => {
  const { getDetails, getMenus } = useMainInformation();

  const { data: menus } = useQuery('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: details } = useQuery(['Details', selectedMenuId], () => getDetails(selectedMenuId!), {
    enabled: !!selectedMenuId,
  });

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[1].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      color="black"
      py={2}
      px={2}
      textAlign="center"
      width="100%"
      sx={{
        background: 'linear-gradient(to right, #3393AE, #DBDBDB)', // couleur d'arrière-plan similaire à celle de l'image
        marginTop: '30px',
      }}
    >
      <Typography sx={{ marginTop: '50px' }}></Typography>
      
      <Grid container spacing={3} justifyContent="center">
        {details?.map((detail) => (
          <Grid item xs={12} md={6} key={detail.id} textAlign="center">
            <Typography
              variant="h1" // Ajuste la taille du texte pour les chiffres
              sx={{
                color: '#3393AE', // couleur verte pour les chiffres
                fontFamily: 'Poppins',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)', // effet d'ombre
                fontWeight: 'bold',
              }}
            >
              {detail.nombre}
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Poppins',
                fontWeight: 'bold',
                color: 'black',
                textTransform: 'uppercase', // pour correspondre au style majuscule
              }}
            >
              {detail.titre}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Typography sx={{ marginTop: '50px' }}></Typography>
    </Box>
  );
};

export default Details;
