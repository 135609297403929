import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import Fontionalite from './setup/Fontionalite';
import DetailsFontions from './setup/DetailsFontions';





function Fonction() {
 

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        
        {/* Affichage des images associées au menu sélectionné */}
        <Grid item xs={12}>
          <Stack flexDirection="column">
           
           
            <Fontionalite/>
            <DetailsFontions/>

            


          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Fonction;
