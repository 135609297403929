import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardMedia, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Slider from 'react-slick';
import useMainInformation from 'features/setup/services/MainInformation';
import { IGalery, IMenu } from './models/MainInformation';
import { globalConfig } from 'config';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Photos = () => {
  const { t } = useTranslation();
  const { getGalery, getMenus } = useMainInformation();

  const { data: menus } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: galeries } = useQuery<IGalery[]>(
    ['Galery', selectedMenuId],
    () => getGalery(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[1].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const settings = {
    dots: true, // Affiche les points pour naviguer entre les slides
    infinite: true, // Boucle infinie
    speed: 1000, // Vitesse de transition entre les slides (1 seconde)
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Temps d'affichage de chaque slide (5 secondes)
    pauseOnHover: true, // Pause le diaporama lorsqu'on passe la souris dessus
    prevArrow: <div style={{ display: 'block', color: 'black' }}>‹</div>, // Personnalisation des flèches
    nextArrow: <div style={{ display: 'block', color: 'black' }}>›</div>,
  };

  return (
    <Box bgcolor="#fff" color="black"  textAlign="center" sx={{ marginTop: '50px' }}>
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
        Quelques images de notre solution
      </Typography>

      <Typography sx={{ marginTop: '40px' }}></Typography>

      <Slider {...settings}>
        {galeries?.map((galerie) => (
          <Box key={galerie.id} >
            <Card>
              <CardMedia
                component="img"
                height="500"
                sx={{ width: '100%', objectFit: 'fill' }}
                image={`${globalConfig.get().apiUrl}/download/${galerie.image_Url}`}
                alt={galerie.image}
              />
            </Card>
          </Box>
        ))}
      </Slider>

      <Typography sx={{ marginTop: '50px' }}></Typography>
    </Box>
  );
};

export default Photos;
