import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IIMage4Carousel, IMenu } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';
import CarouselImages from 'components/ui/CarousselImages';
import { useTranslation } from 'react-i18next';
import DefStaff from './setup/DefTarif';
import Prix from './setup/Prix';





function Tarif() {
 

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        
        {/* Affichage des images associées au menu sélectionné */}
        <Grid item xs={12}>
          <Stack flexDirection="column">
          <DefStaff/>
          <Prix/>
         


          
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Tarif;
