import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IIMage4Carousel, IMenu } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';
import CarouselImages from 'components/ui/CarousselImages';
import { useTranslation } from 'react-i18next';
import DefAboutUs from './setup/DefAboutUs';
import OurValues from './setup/OurValues';
import OurHistory from './setup/OurHistory';
import About from './setup/About';
import Details from './setup/Details';
import Photos from './setup/Photos';



function Abouts() {
 

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        
        {/* Affichage des images associées au menu sélectionné */}
        <Grid item xs={12}>
          <Stack flexDirection="column">
          
          <DefAboutUs/>
          <About/>
          <OurHistory/>
          <Details/>
          <Photos/>




          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Abouts;
