import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import {  IMenu, IReference } from './models/MainInformation';
import { globalConfig } from 'config';

const Reference = () => {
  const { t } = useTranslation();
  const { getReference, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: references } = useQuery<IReference[]>(
    ['Reference', selectedMenuId],
    () => getReference(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box  py={2} px={2} textAlign="center" width="100%" sx={{backgroundImage: 'linear-gradient(to right, #209CED, #DBDBDB)', marginTop:'50px'}}>
        
        <Box>
        <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
        Approuvé et utilisé
      </Typography>

      <Typography variant="h6" sx={{ fontFamily: 'Poppins' }}>Dans tout le pays (Cameroun) et même à l’étranger, ils nous font confiance.</Typography>
      <Typography sx={{ marginTop: '40px' }}>
        {/* Additional introduction or description can go here */}
      </Typography>
        </Box>

      <Typography variant="h4" component="div" sx={{ }}>
      Quelques références
      </Typography>

      <Typography sx={{ marginTop: '40px' }}>
        {/* Additional introduction or description can go here */}
      </Typography>

      <Grid container spacing={3} alignItems="stretch">
        {references?.map((reference, index) => (
          <Grid item xs={6} md={2} key={reference.id}>
            <Box display="flex" flexDirection={isSmallScreen ? 'row' : 'row'} alignItems="center" height="100%">
              <Box flexShrink={0} mb={isSmallScreen ? 2 : 0} pr={isSmallScreen ? 0 : 2}>
                <img 
                  src={`${globalConfig.get().apiUrl}/download/${reference.image_Url}`} 
                  alt={reference.image} 
                  style={{ width: '150px', height: '150px' }}
                />
              </Box>
              
            </Box>
          </Grid>
        ))}
         
      </Grid>
      <Typography variant="h6" sx={{ marginTop: '50px',fontFamily: 'Poppins', fontWeight: '500'  }}>Et bien d'autres</Typography>
      <Typography sx={{ marginTop: '50px' }}></Typography>
    </Box>
  );
};

export default Reference;
