import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme, Card, CardContent, CardMedia } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom'; // Importation du composant Link
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu, IAllFonctionnalite } from './models/MainInformation';
import { globalConfig } from 'config';

const AllFonctionnality = () => {
  const { t } = useTranslation();
  const { getAllFonctionality, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: fonctionnalites } = useQuery<IAllFonctionnalite[]>(
    ['AllFonctionnalite', selectedMenuId],
    () => getAllFonctionality(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[3].id); // Changez cette ligne si vous voulez sélectionner un autre menu par défaut
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box bgcolor="#fff" color="black" py={2} px={2} textAlign="center" width="100%" sx={{ marginTop: '50px' }}>
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
        Toutes nos fonctionnalités
      </Typography>

      <Grid container spacing={3} alignItems="stretch" sx={{ marginTop: '40px' }}>
        {fonctionnalites && fonctionnalites.length > 0 ? (
          fonctionnalites.map((fonctionnalite) => (
            <Grid item xs={12} md={3} key={fonctionnalite.id}>
              <Link
                to={`/Details-scolarite/${fonctionnalite.id}`}
                state={{ fonctionnalites }}
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${globalConfig.get().apiUrl}/download/${fonctionnalite.image_Url}`}
                    alt={fonctionnalite.image}
                    sx={{ objectFit: 'contain', padding: '16px' }}
                  />
                  <CardContent>
                    <Typography variant="h5" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                      {fonctionnalite.titre}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Cliquez pour voir plus
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))
        ) : (
          <Typography variant="body1">Aucune fonctionnalité disponible</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default AllFonctionnality;
