import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Typography, Card, CardContent, CardMedia, Grid } from '@mui/material';
import { IFonctionnalite } from './models/MainInformation'; // Assurez-vous que le type est correctement importé
import { globalConfig } from 'config';

const Fontionalite = () => {
  const { id } = useParams<{ id: string }>(); // `id` est de type string | undefined
  const location = useLocation();
  const fonctionnalites = location.state?.fonctionnalites as IFonctionnalite[] | undefined; // Typage explicite

  // Vérifier si l'id est défini avant de l'utiliser
  const fonctionnalite = id && fonctionnalites?.find((f: IFonctionnalite) => f.id === parseInt(id));

  if (!fonctionnalite) {
    return <Typography>Fonctionnalité non trouvée</Typography>;
  }

  return (
    <Box bgcolor="#fff" color="black" textAlign="center" width="100%">
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Card sx={{ display: 'flex', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              sx={{
                width: { xs: '80%', md: '50%' },  // 80% de largeur sur petit écran, 50% sur moyen et grand écran
                height: { xs: 400, md: 500 },     // 400px de hauteur sur petit écran, 500px sur moyen et grand écran
                objectFit: 'contain'
              }}
              image={`${globalConfig.get().apiUrl}/download/${fonctionnalite.image_Url}`}
              alt={fonctionnalite.titre}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', mb: 2 }}>
              {fonctionnalite.titre}
            </Typography>
            <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '400', textAlign:'justify' }}>
              {fonctionnalite.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Fontionalite;
