import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardMedia, Button, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu, IServiceClient } from './models/MainInformation';
import { globalConfig } from 'config';
import { useNavigate } from 'react-router-dom';

const ServiceClient = () => {
  const { t } = useTranslation();
  const { getServiceClient, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: services } = useQuery<IServiceClient[]>(
    ['ServiceClient', selectedMenuId],
    () =>  getServiceClient(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate('/contact'); // Redirige vers la page /fonctionnalites
  };

  return (
    <Box bgcolor="#fff" color="black"  textAlign="center" sx={{ marginTop: '50px' }} >
      <Grid container spacing={3}>
        {services?.map((service) => (
          <Grid item xs={12} md={12} key={service.id}>
            <Card>
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="500"
                  sx={{ width: '100%', objectFit: 'fill' }}
                  image={`${globalConfig.get().apiUrl}/download/${service.image_Url}`}
                  alt={service.titrePrincipal}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    bgcolor: 'rgba(0, 0, 0, 0.5)', // Voile semi-transparent
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontFamily: 'Poppins',
                    textAlign: 'center',
                    px: 2
                  }}
                >
                  <Typography variant={isSmallScreen ? "h6" : "h4"} component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                    {service.titrePrincipal}
                  </Typography>

                  <Typography variant={isSmallScreen ? "h6" : "h4"} component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                    {service.titreSecondaire}
                  </Typography>

                  <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
        <Button variant="contained" color="primary" sx={{ fontFamily: 'Poppins', fontWeight: '500', height:'50px', color:'#fff' }} onClick={handleButtonClick}>
          Nous Contacter
        </Button>
      </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Typography sx={{ marginTop: '50px' }}></Typography>
    </Box>
  );
};

export default ServiceClient;
