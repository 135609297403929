import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { IIMage4Carousel, IMenu } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import { globalConfig } from 'config';
import CarouselImages from 'components/ui/CarousselImages';
import { useTranslation } from 'react-i18next';
import Welcome from './setup/Welcome';
import Enseignement from './setup/Enseignement';
import Evenements from './setup/Evenements';
import Donation from './setup/Donation';
import Newletters from './setup/Newletters';
import HeroSection from './setup/HeroSection';
import Management from './setup/Management';
import Fonctionnalites from './setup/Fonctionnalites';
import Reference from './setup/Reference';
import ServiceClient from './setup/ServiceClient';
import Advantages from './setup/Advantages';
import FAQ from './setup/FAQ';
import Newsletter from './setup/Newsletter';



function Accueil() {
 

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        
        {/* Affichage des images associées au menu sélectionné */}
        <Grid item xs={12}>
          <Stack flexDirection="column">
           
            <HeroSection/>
            <Management/>
            <Advantages/>

            <Fonctionnalites/>
            <Reference/>
            <ServiceClient/>
            <FAQ/>
            <Newsletter/>


          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Accueil;
