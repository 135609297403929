import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { isFalsy } from 'utility-types';
import { useQuery } from 'react-query';
import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import useMainInformation from 'features/setup/services/MainInformation';
import ScrollingMessage from './ScrollingMessage';

export const Footer = () => {
  const { t, i18n } = useTranslation();  

  const { getMainInformations } = useMainInformation();

  const {data: mainInformations} = useQuery<IMainInformation[]>( ['MainInformation'], () => getMainInformations());

  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);
  
  useEffect(() => {
    if(!isFalsy(mainInformations) && mainInformations?.length>0)
      setMainInformation(mainInformations[0]);
  }, [mainInformations]);

  return (
    <Box sx={{ background: '#333', color: '#fff', padding: 2 }}>
      <Container maxWidth="xl">
        <Box textAlign="center" mb={2}>
          <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: '500', mb: 1 }}>
            {t('Suivez-nous sur les réseaux sociaux')}
          </Typography>
          <Box>
            <a href={mainInformation.lienFacebook} target="_blank" rel="noopener noreferrer">
              <FacebookIcon sx={{ color: '#3b5998', fontSize: 30, mx: 1 }} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <InstagramIcon sx={{ color: '#E1306C', fontSize: 30, mx: 1 }} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <TwitterIcon sx={{ color: '#1DA1F2', fontSize: 30, mx: 1 }} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <LinkedInIcon sx={{ color: '#0077b5', fontSize: 30, mx: 1 }} />
            </a>
          </Box>
        </Box>
        <Divider sx={{ backgroundColor: '#fff' }} />
        <Grid container justifyContent="center" sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Typography 
              component="h3" 
              sx={{ 
                fontFamily: 'Poppins', 
                fontWeight: '500',
                textAlign: 'center', 
                overflowWrap: 'break-word', 
                wordWrap: 'break-word', 
                hyphens: 'auto' 
              }} 
              color="white"
            >
              {`Copyright © 2024 E-Kampux tous droits réservés`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
      
    </Box>
  );
};
