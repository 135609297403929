
import { Box, Grid, Stack, Typography } from '@mui/material';
import DefFonctionnalite from './setup/DefFonctionnalite';
import AllFonctionnality from './setup/AllFonctionnality';



function Fonctionnalites() {
 

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        
        {/* Affichage des images associées au menu sélectionné */}
        <Grid item xs={12}>
          <Stack flexDirection="column">
          
          <DefFonctionnalite/>
          <AllFonctionnality/>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Fonctionnalites;
