import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom'; // Importer useNavigate
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu, IFonctionnalite } from './models/MainInformation';
import { globalConfig } from 'config';
import { Link } from 'react-router-dom';

const Fonctionnalites = () => {
  const { t } = useTranslation();
  const { getFonctionnalite, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: fonctionnalites } = useQuery<IFonctionnalite[]>(
    ['Fonctionnalite', selectedMenuId],
    () => getFonctionnalite(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // Utiliser useNavigate

  const handleButtonClick = () => {
    navigate('/fonctionnalites'); // Redirige vers la page /fonctionnalites
  };

  return (
    <Box bgcolor="#fff" color="black" py={2} px={2} textAlign="center" width="100%" sx={{ marginTop: '50px' }}>
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
        Nos Fonctionnalités
      </Typography>

      <Grid container spacing={3} alignItems="stretch" sx={{ marginTop: '40px' }}>
        {fonctionnalites?.map((fonctionnalite) => (
          <Grid item xs={12} md={3} key={fonctionnalite.id}>
             <Link
                to={`/Details-scolarites/${fonctionnalite.id}`}
                state={{ fonctionnalites }}
                style={{ textDecoration: 'none' }}
              >
                <Card
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={`${globalConfig.get().apiUrl}/download/${fonctionnalite.image_Url}`}
                    alt={fonctionnalite.image}
                    sx={{ objectFit: 'contain', padding: '16px' }}
                  />
                  <CardContent>
                    <Typography variant="h5" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                      {fonctionnalite.titre}
                    </Typography>
                    
                    <Typography variant="body2" color="textSecondary">
                      Cliquez pour voir plus
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ marginTop: '50px', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ fontFamily: 'Poppins', fontWeight: '900', height: '50px', color: '#fff' }}
          onClick={handleButtonClick} // Ajouter onClick
        >
          Voir toutes les fonctionnalités
        </Button>
      </Box>
    </Box>
  );
};

export default Fonctionnalites;
