import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Grid, Button, IconButton, Menu, MenuItem, Divider, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu } from 'features/setup/models/MainInformation';
import { Link } from 'react-router-dom';
import { IMainInformation, defaultMainInformation } from 'features/setup/models/MainInformation';
import { isFalsy } from 'utility-types';
import { globalConfig } from 'config';

const HeaderMenu = () => {
  const { getMenus, getMainInformations } = useMainInformation();
  const { data: menuData } = useQuery<IMenu[]>(['Menu'], getMenus);
  const [menus, setMenus] = useState<IMenu[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const redirectPaths: string[] = [
    '/', // Route for the first menu
    '/a-propos-de-nous', // Route for the second menu
    '/Tarif',
    '/fonctionnalites',
    '/contact',
  ];

  useEffect(() => {
    if (menuData) {
      setMenus(menuData);
    }
  }, [menuData]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: mainInformations } = useQuery<IMainInformation[]>(['MainInformation'], () => getMainInformations());
  const [mainInformation, setMainInformation] = useState<IMainInformation>(defaultMainInformation);

  useEffect(() => {
    if (!isFalsy(mainInformations) && mainInformations?.length > 0) {
      setMainInformation(mainInformations[0]);
    }
  }, [mainInformations]);

  const handleMenuClick = (index: number) => {
    const path = redirectPaths[index];
    window.open(path, '_self'); // Opens the path in the same tab
    handleClose();
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', height: '75px' }}>
      <Toolbar>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={2} container justifyContent="flex-start" alignItems="center">
            <Link to="/">
              <img
                src={`${globalConfig.get().apiUrl}/download/${mainInformation.logo_Url}`}
                alt="Logo"
                style={{ marginRight: '10px', width: 130, height: '75px', marginTop: '7px' }}
              />
            </Link>
          </Grid>

          <Grid
            item
            xs={8}
            container
            justifyContent="justify"
            alignItems="center"
            spacing={1} // Adds spacing between menu items
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: '50px', // Adds space between the menu items
            }}
          >
            {menus.map((menu, index) => (
              <Button
                key={index}
                color="inherit"
                sx={{
                  color: '#333',
                  fontWeight: '500',
                  fontSize: '0.95rem',
                  fontFamily: 'Poppins',
                  margin: '0 10px',
                  '&:hover': { color: '#000' },
                }}
                onClick={() => handleMenuClick(index)}
              >
                {menu.menu.toUpperCase()}
              </Button>
            ))}
          </Grid>

          <Grid item xs={2} container justifyContent="flex-end" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '5px' }}>
              <IconButton color="inherit" onClick={handleClick} edge="start" aria-label="menu">
                <MenuIcon sx={{ color: '#000', fontSize: '45px' }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} sx={{ right: 0 }}>
          {menus.map((menu, index) => (
            <div key={index}>
              <MenuItem onClick={() => handleMenuClick(index)}>
                <Typography variant="body1" fontWeight="bold" color="black">
                  {menu.menu.toUpperCase()}
                </Typography>
              </MenuItem>
              <Divider />
            </div>
          ))}
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderMenu;
