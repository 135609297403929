import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IHeroSection, IMenu } from './models/MainInformation';
import { globalConfig } from 'config';

const HeroSection = () => {
  const { t } = useTranslation();
  const { getHeroSection, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: heros } = useQuery<IHeroSection[]>(
    ['HeroSection', selectedMenuId],
    () => getHeroSection(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      color="black"
      py={2}
      px={2}
      textAlign="center"
      width="100%"
      sx={{ 
        background: '#fff'
      }}
    >
      <Grid container spacing={3}>
        {heros?.map((hero) => (
          <Grid container item xs={12} spacing={2} key={hero.id}>
            
            <Grid 
              item 
              xs={12} 
              md={6} 
              display="flex" 
              flexDirection="column" 
              justifyContent="center" 
              sx={{ 
                background: 'linear-gradient(to right, #3393AE, #DBDBDB)', 
                padding: isSmallScreen ? '20px' : '40px' // Ajout de padding pour ajuster la position
              }}
            >
              <Typography 
                variant={isSmallScreen ? "h4" : "h3"}  
                component="div" 
                sx={{ 
                  fontFamily: 'Poppins', 
                  fontWeight: '900',
                  color:'#fff',
                  marginBottom: '20px' // Réduire la marge inférieure pour rapprocher le titre de la description
                }}
              >
                {hero.titre}
              </Typography>
              <Typography 
                variant="h6" 
                component="div" 
                sx={{ 
                  fontFamily: 'Poppins',
                  marginBottom: '140px' // Réduire la marge inférieure pour rapprocher la description de l'image
                }}
              >
                {hero.description}
              </Typography>
            </Grid>

            <Grid 
              item 
              xs={12} 
              md={6} 
              display="flex" 
              justifyContent="center" 
              alignItems="center"
              sx={{ 
                padding: isSmallScreen ? '10px' : '20px', // Réduire le padding pour rapprocher l'image
              }}
            >
              <img 
                src={`${globalConfig.get().apiUrl}/download/${hero.image_Url}`} 
                alt={hero.image} 
                style={{ width: '100%', height: 'auto', maxHeight: '500px' }} // Utilisation de maxHeight pour limiter la taille
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HeroSection;
