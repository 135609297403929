import React from 'react';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';

const Newsletter = () => {
  return (
    <Box
      py={6}
      px={4}
      sx={{
        background: 'linear-gradient(to right, #3393AE, #DBDBDB)',
        textAlign: 'center',
        color: '#fff',
        marginTop:'50px'
      }}
    >
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '600', marginBottom: '20px' }}>
      Abonnez-vous à notre newsletter et restez à jour !
      </Typography>
      <Typography variant="body1" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '400', marginBottom: '40px' }}>
      Notre équipe vous alertera par email à chaque mise à jour du logiciel.
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Entrez votre adresse email"
            inputProps={{
              style: {
                textTransform: 'none',
                fontFamily: 'Poppins',
                backgroundColor: '#fff',
                 borderRadius: '8px',

              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              height: '100%',
              borderRadius: '8px',
              fontFamily: 'Poppins',
              fontWeight: '600',
            }}
          >
            S'inscrire
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Newsletter;
