import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useQuery } from 'react-query';
import { globalConfig } from 'config';
import useMainInformation from 'features/setup/services/MainInformation';

const DetailsFontions = () => {
  const { getDetailfonction } = useMainInformation();
  const { id } = useParams<{ id: string }>(); // Récupère l'ID de la fonctionnalité depuis l'URL
  const fonctionnaliteId = parseInt(id || '0', 10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: fonctionsDetails, isLoading, isError } = useQuery(
    ['Detailfonction', fonctionnaliteId],
    () => getDetailfonction(fonctionnaliteId),
    {
      enabled: !!fonctionnaliteId, // N'exécute la requête que si l'ID est valide
    }
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !fonctionsDetails || fonctionsDetails.length === 0) {
    return <Typography sx={{ fontFamily: 'Poppins', fontWeight: '500', textAlign: 'center' }}>Aucune donnée disponible.</Typography>;
  }

  return (
    <Box bgcolor="#fff" color="black" py={2} px={2} textAlign="center" width="100%" sx={{ marginTop: '50px' }}>
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '20px' }}>
        Détails de la scolarité
      </Typography>

      <Grid container spacing={3} alignItems="stretch">
        {fonctionsDetails?.map((Detail) => (
          <Grid item xs={12} md={4} key={Detail.id}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              textAlign="center"
              sx={{
                backgroundColor: '#fff',
                borderRadius: '8px',
                padding: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box mb={2}>
                <img
                  src={`${globalConfig.get().apiUrl}/download/${Detail.image_Url}`}
                  alt={Detail.image}
                  style={{ width: '80px', height: '80px', objectFit: 'contain' }}
                />
              </Box>
              <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '8px' }}>
                {Detail.titre}
              </Typography>
              <Typography variant="body1" component="div" sx={{ fontFamily: 'Poppins', textAlign: 'justify' }}>
                {Detail.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DetailsFontions;
