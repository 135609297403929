import { HeadCell } from 'components/ui/EnhancedTable';
//import useAxios from 'library/axios'; 
import { useState } from 'react';

import axios from 'axios';

import { useTranslation  } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IAbout, IAllFonctionnalite, IAvantage, IDefAboutUs,  IDefContact, IDefFonctionnalite,  IDefTarif, IDetailFonction, IDetails, IDetailsScolarite,  IFAQ, IFonctionnalite, IGalery, IHeroSection, IHistory, IMainInformation, IManagement, IMenu, IMessage, IOurHistory, IPrix, IReference, IServiceClient} from "../models/MainInformation";

import { globalConfig } from 'config';
import { IService } from 'features/production/models/Appointment';
import { get } from 'lodash';


const _ = () => {

    //const axios = useAxios();     

    // const createContact = async (contact: IContact)  =>       
    //     await (await axios.post('/api/production/contact/create', contact)).data;       
        
    // const updateContact = async (contact: IContact)  =>       
    //     await (await axios.post('/api/production/contact/update', contact)).data; 
    
    // const getContact = async (id  : number )  => {
    //   const {data} = (await axios.get(`/api/production/contact/get-contact/${id}`));
    //   return await data;
    // }

    const getMainInformations = async (pagination?: IPagination) : Promise<IMainInformation[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;

      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/main/get-contents`));
      return await data;
    }

   
    const getMenus = async (): Promise<IMenu[]> => {
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/menu/get-contents`));
      return await data;
    };

   

  


    const getDefAbout= async (menuId: number): Promise<IDefAboutUs[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/defAbout/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

  

    const getHistory = async (menuId: number): Promise<IHistory[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/history/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };
    
    const getDefStaff = async (menuId: number): Promise<IDefTarif[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/defTarif/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

   
    
    const getDefFonctionnalite = async (menuId: number): Promise<IDefFonctionnalite[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/defFonctionnalite/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getGalery = async (menuId: number): Promise<IGalery[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/galery/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getDefContact = async (menuId: number): Promise<IDefContact[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/defContact/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getHeroSection = async (menuId: number): Promise<IHeroSection[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/hero/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getManagement = async (menuId: number): Promise<IManagement[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/manage/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getFonctionnalite = async (menuId: number): Promise<IFonctionnalite[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/fonctionality/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getReference = async (menuId: number): Promise<IReference[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/reference/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getServiceClient = async (menuId: number): Promise<IServiceClient[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/service/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getAdvantage = async (menuId: number): Promise<IAvantage[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/advantage/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getAbout = async (menuId: number): Promise<IAbout[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/about/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getOurHistory = async (menuId: number): Promise<IOurHistory[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/history/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getDetails = async (menuId: number): Promise<IDetails[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/detail/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getPrix = async (menuId: number): Promise<IPrix[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/tarif/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getFAQ = async (menuId: number): Promise<IFAQ[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/faq/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getAllFonctionality = async (menuId: number): Promise<IAllFonctionnalite[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/allFonctionality/get-contents?parentContentId=${menuId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getMessages = async (pagination?: IPagination) : Promise<IMessage[]> => {
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      //const {name, description, phoneNumber} = criteria;

      
      const {data} = (await axios.get(`${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/message/get-contents`));
      return await data;
    }

    const getDetailScolarite = async (fonctionalityId: number): Promise<IDetailsScolarite[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/scolarite/get-contents?parentContentId=${fonctionalityId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

    const getDetailfonction = async (fonctionalityId: number): Promise<IDetailFonction[]> =>  {
      const url = `${globalConfig.get().apiUrl}/api/production/content/v1/${globalConfig.get().applicationApiToken}/detailFonctionalite/get-contents?parentContentId=${fonctionalityId}`;
      console.log('Fetching URL:', url);
      const { data } = await axios.get(url);
      return data;
    };

  
      
    return {    
      getMainInformations,
      getMenus,
     

      getDefAbout,
     
      getHistory,

      getDefContact,

      getDefFonctionnalite,
      getGalery,

      getDefStaff,
     

      getHeroSection,
      getManagement,
      getFonctionnalite,
      getReference,
      getServiceClient,
      getAdvantage,

      getAbout,
      getOurHistory,
      getDetails,
      getPrix,
      getFAQ,
      getAllFonctionality,

      getMessages,
      getDetailScolarite,
      getDetailfonction

    
    } 
}

export default _;

