import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu, IPrix } from './models/MainInformation';

const Prix = () => {
  const { t } = useTranslation();
  const { getPrix, getMenus } = useMainInformation();

  const { data: menus } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: prix } = useQuery<IPrix[]>(
    ['Prix', selectedMenuId],
    () => getPrix(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[2].id); // Sélectionne un menu par défaut
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{background: 'linear-gradient(to right, #3393AE, #DBDBDB)'}} py={4} px={2} textAlign="center" width="100%">
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '20px' }}>
        {t('Tableau de prix')}
      </Typography>

      <Typography variant="body1" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '40px' }}>
        {t('L’ensemble des offres concerne tous les modules de l’application. Les frais de licence annuelle sont généraux quel que soit la formule choisie.')}
      </Typography>

      <Grid container spacing={3} alignItems="stretch">
        {prix?.map((price) => (
          <Grid item xs={12} md={3} key={price.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '16px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                padding: '20px',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardContent>
                <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: '600', marginBottom: '10px' }}>
                  {price.titre}
                </Typography>
                <Typography variant="h5" sx={{ fontFamily: 'Poppins', fontWeight: '900', color: theme.palette.primary.main }}>
                  {price.montant}
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: 'Poppins', marginTop: '10px' }}>
                  {price.desc1}
                </Typography>
                {price.desc2 && <Typography variant="body2" sx={{ fontFamily: 'Poppins', marginTop: '5px' }}>{price.desc2}</Typography>}
                {price.desc3 && <Typography variant="body2" sx={{ fontFamily: 'Poppins', marginTop: '5px' }}>{price.desc3}</Typography>}
                {price.desc4 && <Typography variant="body2" sx={{ fontFamily: 'Poppins', marginTop: '5px' }}>{price.desc4}</Typography>}
                {price.desc5 && <Typography variant="body2" sx={{ fontFamily: 'Poppins', marginTop: '5px' }}>{price.desc5}</Typography>}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Prix;
