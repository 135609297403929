import { IMessage, defaultMessage } from 'features/setup/models/MainInformation';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { isFalsy } from 'utility-types';
import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const ScrollingMessage = () => {
    const { getMessages } = useMainInformation();
    const { data: messages } = useQuery<IMessage[]>(['Message'], () => getMessages());

    const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(0);
    const [visible, setVisible] = useState<boolean>(true);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (!isFalsy(messages) && messages?.length > 0) {
            const messageCount = messages.length;

            const cycleInterval = setInterval(() => {
                setVisible(true);

                setTimeout(() => {
                    setVisible(false);
                    setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messageCount);

                    setTimeout(() => {
                        setVisible(true);
                    }, 15000);

                }, 15000);

            }, 30000);

            return () => clearInterval(cycleInterval);
        }
    }, [messages]);

    const message = messages?.[currentMessageIndex] || defaultMessage;

    return (
        <Box sx={{
           
            bottom: 0,
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            padding: '8px 0',
            zIndex: 1000,
            overflow: 'hidden',
            display: visible ? 'block' : 'none',
            transition: 'opacity 0.5s ease-in-out',
            opacity: visible ? 1 : 0,
            textAlign: 'center',
            marginBottom:'5px'
          
        }}>
            <Box sx={{
                display: 'inline-block',
                whiteSpace: 'normal', // Normal wrap for all screens
                animation: 'none',  // No animation for all screens
                 // Smaller font size on small screens
                fontWeight: '800',
                fontFamily: 'Poppins',
                width: '100%',
            }}>
                <Typography variant={isSmallScreen ? "caption" : "body1"} sx={{ display: 'inline-block',fontFamily: 'Poppins' }}>
                    {message.message}
                </Typography>
            </Box>
        </Box>
    );
};

export default ScrollingMessage;
