import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu, IFAQ } from './models/MainInformation';

const FAQ = () => {
  const { t } = useTranslation();
  const { getFAQ, getMenus } = useMainInformation();

  const { data: menus } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: questions } = useQuery<IFAQ[]>(
    ['FAQ', selectedMenuId],
    () => getFAQ(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id); // Select a default menu
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{background: 'linear-gradient(to right, #3393AE, #DBDBDB)'}} py={4} px={isSmallScreen ? 2 : 4} textAlign="center" width="100%">
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '20px' }}>
        {t('Avez-vous des questions ?')}
      </Typography>
      <Typography variant="h5" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500', marginBottom: '20px' }}>
        {t("Vous pouvez poser n'importe quelle question.")}
      </Typography>

      <Typography variant="body1" component="div" sx={{ fontFamily: 'Poppins', marginBottom: '40px' }}>
        {t('Nous avons préparé quelques réponses pour certaines questions que vous vous posez souvent avant de nous joindre.')}
      </Typography>

      <Grid container spacing={isSmallScreen ? 2 : 4} alignItems="stretch">
        {questions?.map((question) => (
          <Grid item xs={12} md={6} key={question.id}>
            <Accordion
              sx={{
                borderRadius: '12px',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                '&:not(:last-child)': {
                  marginBottom: '20px',
                },
                '&:before': {
                  display: 'none',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${question.id}-content`}
                id={`panel${question.id}-header`}
                sx={{
                  backgroundColor: theme.palette.background.default,
                  borderRadius: '12px',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: 'Poppins',
                    fontWeight: '600',
                  }}
                >
                  {question.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: 'Poppins',
                    textAlign:'justify',
                    lineHeight:'35px',
                    fontWeight: '400',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {question.reponse}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      

    </Box>
  );
};

export default FAQ;
