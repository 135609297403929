import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, useMediaQuery, useTheme, Card, CardContent, CardMedia, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useMainInformation from 'features/setup/services/MainInformation';
import { IMenu,  IAvantage } from './models/MainInformation';
import { globalConfig } from 'config';

const Advantages = () => {
  const { t } = useTranslation();
  const { getAdvantage, getMenus } = useMainInformation();

  const { data: menus, isLoading: menusLoading, isError: menusError } = useQuery<IMenu[]>('Menu', getMenus);
  const [selectedMenuId, setSelectedMenuId] = useState<number | null>(null);

  const { data: avantages } = useQuery<IAvantage[]>(
    ['Avantage', selectedMenuId],
    () => getAdvantage(selectedMenuId!),
    { enabled: !!selectedMenuId }
  );

  useEffect(() => {
    if (menus && menus.length > 0) {
      setSelectedMenuId(menus[0].id);
    }
  }, [menus]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box bgcolor="#fff" color="black" py={2} px={2} textAlign="center" width="100%">
      <Typography variant="h4" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
        Avantages pour l'école
      </Typography>

      <Grid container spacing={3} alignItems="stretch" sx={{ marginTop: '40px' }}>
        {avantages?.map((avantage) => (
          <Grid item xs={12} md={3} key={avantage.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '16px',  // Ajout du borderRadius
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',  // Ajout d'une ombre pour donner un effet de profondeur
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={`${globalConfig.get().apiUrl}/download/${avantage.image_Url}`}
                alt={avantage.image}
                sx={{ objectFit: 'contain', padding: '16px' }}  // Ajout d'un padding pour que l'image soit bien centrée
              />
              <CardContent>
                <Typography variant="h5" component="div" sx={{ fontFamily: 'Poppins', fontWeight: '500' }}>
                  {avantage.titrePrincipal}
                </Typography>
                <Typography variant="body1" component="div" sx={{ fontFamily: 'Poppins'}}>
                  {avantage.titreSecondaire}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

     
    </Box>
  );
};

export default Advantages;
